<template>
  <div class="SkyclarysAdministration">
    <h1>Administration Skyclarys</h1>
    <div class="SkyclarysNavigation">
      <v-btn @click.stop="navigation('admin/list')" class="mx-4 px-6 py-2" color="primary" depressed
        >Voir les documents</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    navigation(target) {
      this.$router.push(target)
    }
  }
}
</script>

<style>
.SkyclarysAdministration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.SkyclarysNavigation {
  display: flex;
  gap: 10px;
  align-items: center;
}
</style>
