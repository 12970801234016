<template>
  <div class="contentAdmin">
    <div class="py-2">
      <v-btn @click.stop="backNav" class="mx-4 px-6 py-2" color="primary" depressed
        >Retour administration skyclarys</v-btn
      >
    </div>
    <!-- <router-link to="/skyclarys/admin">Back</router-link> -->
    <table name="order_array" style="width:100%" class="tableSkyclarysAdmin">
      <thead style="border: 1px solid #d1d1d1;">
        <tr>
          <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px; width: 20%">
            <mybb-text weight="bold">Date</mybb-text>
          </th>
          <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px; width: 70%">
            <mybb-text weight="bold">Nom du fichier</mybb-text>
          </th>
          <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px; width: 10%">
            <mybb-text weight="bold">Télécharger</mybb-text>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="document in documents">
          <td>
            {{ document.date }}
          </td>
          <td>
            {{ document.name }}
          </td>
          <td>
            <button @click="getDocument(document.key)">télécharger</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import { format } from 'date-fns'

export default {
  data() {
    return {
      documents: []
    }
  },

  mounted() {
    this.getAllDocuments()
  },

  methods: {
    backNav() {
      this.$router.push('/skyclarys/admin')
    },

    async getAllDocuments() {
      try {
        const { data } = await axios.get('/skyclarys/files')

        if (Array.isArray(data.files) && data.files.length > 0) {
          this.documents = data.files.map(file => ({
            date: format(new Date(file.LastModified), 'dd/MM/yyyy hh:mm:ss'),
            name: file.Key.includes('_skyclarys_')
              ? file.Key.split('_skyclarys_')[1]
              : file.Key.split('/skyclarys/')[1],
            key: file.Key.split('/skyclarys/')[1]
          }))
        }
      } catch (error) {
        console.error(error)
      }
    },

    async getDocument(key) {
      try {
        const url = key.includes('.pdf') ? key.replace(/.pdf/g, '') : key

        const link = document.createElement('a')
        link.href = `/skyclarys/file/${url}`
        link.download = key

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style>
.contentAdmin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tableSkyclarysAdmin {
  tr {
    &:hover {
      background-color: #c6c6c6;
    }
  }
}
</style>
