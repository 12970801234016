<template>
  <v-container>
    <div class="text-center subtitle-1 mt-2">
      <mybb-text weight="bold">Bon de commande SKYCLARYS - Omaveloxolone</mybb-text>
      <v-row>
        <v-col>
          <mybb-text-field v-model="orderNumber" :label="'N° engagement'" />
        </v-col>
        <v-col>
          <date-picker v-model="orderDate" :label="'Date'" />
        </v-col>
      </v-row>
      <v-row>
        <v-col style="display: flex; flex-direction: column; gap: 5px">
          <mybb-text-field v-model="companyName" :label="'Raison Sociale'" />
          <mybb-text-field v-model="companyRegistNumber" :label="'SIRET'" />
          <mybb-text-field v-model="companyVat" :error="errors.companyVat"
            ><template #label>
              * TVA Intracom
            </template></mybb-text-field
          >
        </v-col>
        <v-col> <mybb-textarea v-model="companyAddress" :label="'Adresse'"/></v-col>
      </v-row>
      <mybb-text weight="bold">Facturation</mybb-text>
      <v-row>
        <v-col style="display: flex; flex-direction: column; gap: 5px">
          <mybb-textarea v-model="address" :label="'Adresse de facturation'" />
          <mybb-text-field v-model="phone" :label="'Téléphone'" />
          <mybb-text-field v-model="email" :label="'Email'" />
        </v-col>
        <v-col style="display: flex; flex-direction: column; gap: 5px">
          <mybb-text>Transmission facture</mybb-text>
          <mybb-text-field v-model="transmission.email.value" :label="'Email'" />
          <mybb-text-field v-model="transmission.codeService.value" :label="'Chorus Pro: Code Service'" />
        </v-col>
      </v-row>
      <mybb-text weight="bold">Livraison</mybb-text>
      <v-row>
        <v-col style="display: flex; flex-direction: column; gap: 5px">
          <mybb-textarea v-model="deliveryAddress" :label="'* Adresse de livraison'" :error="errors.deliveryAddress" />
          <mybb-text-field v-model="deliveryPhone" :label="'Téléphone'" />
          <mybb-text-field v-model="deliveryFax" :label="'Fax'" />
          <mybb-text-field v-model="deliveryEmail" :label="'Email'" />
        </v-col>
        <v-col style="display: flex; flex-direction: column; gap: 5px">
          <mybb-text>Coordonnées du pharmacien</mybb-text>
          <mybb-text-field v-model="pharmacist.name" :label="'Nom'" />
          <mybb-text-field v-model="pharmacist.phone" :label="'Téléphone'" />
          <mybb-text-field v-model="pharmacist.email" :label="'Email'" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <date-picker v-model="deliveryDate" :label="'Date de livraison souhaitée'" />
        </v-col>
      </v-row>
      <v-col>
        <table name="order_array">
          <thead style="border: 1px solid #d1d1d1;">
            <tr>
              <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px;">
                <mybb-text weight="bold">Désignation</mybb-text>
              </th>
              <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px;">
                <mybb-text weight="bold">Prix unitaire HT UCD gélule</mybb-text>
              </th>
              <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px;">
                <mybb-text weight="bold">Conditionnement</mybb-text>
              </th>
              <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px;">
                <mybb-text weight="bold">Quantité Minimale de livraison en UCD gélules</mybb-text>
              </th>
              <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px;">
                <mybb-text weight="bold">Quantité commandée en UCD gélules (multiple de 90)</mybb-text>
              </th>
              <th class="text-left" style="border: 1px solid #d1d1d1; border-radius: 5px; padding:0 5px;">
                <mybb-text weight="bold">Montant Total HT</mybb-text>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <mybb-text>Omaveloxolone 50mg gélule, en boite de 90 gélules</mybb-text>
              </td>
              <td>
                <mybb-text>
                  257,7778 €
                </mybb-text>
              </td>
              <td>
                <mybb-text>
                  1 boite de 90 gélules
                </mybb-text>
              </td>
              <td>
                <mybb-text>
                  1 boite de 90 gélules
                </mybb-text>
              </td>
              <td style="position: relative">
                <mybb-text-field
                  v-model="purchase.quantity"
                  type="number"
                  step="90"
                  :error="errors.purchase"
                  :error-messages="errors.purchase ? 'La quantitée souhaitée doit être un multiple de 90' : false"
                >
                  <template #label>
                    * Quantitée
                  </template>
                </mybb-text-field>
              </td>
              <td>
                <mybb-text>{{ totalHt }} €</mybb-text>
              </td>
            </tr>
          </tbody>
        </table>
        <v-row>
          <v-col cols="6">
            <mybb-text class="text-left">N° Identification patient dans le programme</mybb-text>
            <mybb-text-field v-model="patientId" :error="errors.patientId">
              <template #label>
                * Identification patient
              </template>
            </mybb-text-field>
          </v-col>
          <v-col cols="6">
            <mybb-text class="text-left">Nom du médecin prescripteur</mybb-text>
            <mybb-text-field v-model="doctorName" :error="errors.doctorName">
              <template #label>
                * Nom médecin
              </template>
            </mybb-text-field>
          </v-col>
        </v-row>
        <mybb-text weight="semi-bold" class="text-left">Signature et date</mybb-text>
        <v-row>
          <v-col>
            <date-picker v-model="signatureDate" :label="'* Date signature'" :error="errors.signatureDate" />
          </v-col>
        </v-row>
        <div class="wrapper-signature" :class="errors.signature ? 'wrapper-signature-error' : ''">
          <VueSignaturePad id="signature" width="100%" height="200px" ref="signaturePad" class="signature-pad" />
        </div>
        <v-row class="mt-2"
          ><v-btn @click.stop="submit" class="mx-4 px-6 py-2" color="primary" depressed>Valider formulaire</v-btn>
        </v-row>
      </v-col>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios'
import { format } from 'date-fns'
import VueSignaturePad from 'vue-signature-pad'
import DatePicker from '../../components/mybb/ui/DatePicker.vue'
import { jsPDF } from 'jspdf'
import PDFTemplate from '../../../public/skyclarys/template_form.png'

export default {
  name: 'Skyclarys',

  components: {
    VueSignaturePad,
    DatePicker
  },

  data() {
    return {
      orderNumber: '',
      orderDate: '',
      companyName: '',
      companyAddress: '',
      companyRegistNumber: '',
      companyVat: '',
      patientId: '',
      doctorName: '',
      address: '',
      phone: '',
      email: '',
      transmission: {
        email: {
          value: ''
        },
        codeService: {
          value: ''
        }
      },
      deliveryAddress: '',
      deliveryPhone: '',
      deliveryFax: '',
      deliveryEmail: '',
      deliveryDate: '',
      pharmacist: {
        name: '',
        phone: '',
        email: ''
      },
      purchase: {
        quantity: undefined
      },
      signatureDate: '',
      errors: {
        signatureDate: false,
        companyVat: false,
        deliveryAddress: false,
        patientId: false,
        purchase: false,
        signature: false
      }
    }
  },
  methods: {
    cleanErrors() {
      for (const key in this.errors) {
        this.errors = {
          ...this.errors,
          [key]: false
        }
      }
    },
    validateData(key, value) {
      const mandatoryFields = ['companyVat', 'deliveryAddress', 'patientId', 'purchase', 'signatureDate', 'doctorName']

      if (key === 'purchase') {
        const validateQuantity = value.quantity % 90

        if (!value.quantity || (value.quantity && validateQuantity !== 0)) {
          return {
            state: false,
            key
          }
        }
      }

      if (key !== 'purchase' && mandatoryFields.includes(key) && !value) {
        return {
          state: false,
          key
        }
      }

      return {
        state: true,
        key
      }
    },
    validateDatas(values) {
      const errorsKeys = []

      for (const key in values) {
        if (!this.validateData(key, values[key]).state) {
          errorsKeys.push(this.validateData(key, values[key]).key)
        }
      }
      return errorsKeys
    },
    async submit() {
      this.cleanErrors()
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      const values = {
        ...this.$data
      }

      const validatesData = this.validateDatas(values)

      if (validatesData.length > 0) {
        for (const error of validatesData) {
          this.errors = {
            ...this.errors,
            [error]: true
          }
        }

        if (isEmpty) {
          this.errors = {
            ...this.errors,
            signature: true
          }
        }

        return
      }

      const doc = new jsPDF()

      const width = doc.internal.pageSize.getWidth()
      const height = doc.internal.pageSize.getHeight()

      //settings pdf
      doc.setFontSize(9)

      //add template image
      doc.addImage(PDFTemplate, 'PNG', 0, 0, width, height)

      //add biogen logo
      // doc.setFillColor(255, 255, 255)
      // doc.setDrawColor(255, 255, 255)
      // doc.rect(144, 2, 60, 20, 'F')
      // doc.addImage(BiogenLogo, 'PNG', 144, 2, 60, 20)

      //complete form with data
      //main
      doc.text(this.orderNumber, 60, 18)
      if (this.orderDate) {
        doc.text(format(new Date(this.orderDate), 'dd/MM/yyyy'), 110, 18)
      }
      doc.text(this.companyName, 38, 29)

      if (this.companyAddress) {
        const wrappedCompanyAddress = doc.splitTextToSize(this.address, 55)
        doc.text(wrappedCompanyAddress, 38, 36)
      }

      doc.text(this.companyRegistNumber, 38, 53)
      doc.text(this.companyVat, 58, 63)

      //invoicing
      if (this.address) {
        const wrappedInvoicingAddress = doc.splitTextToSize(this.address, 55)
        doc.text(wrappedInvoicingAddress, 38, 82)
      }

      doc.text(this.phone, 38, 100)
      doc.text(this.email, 38, 105)

      doc.text(this.transmission.email.value, 137, 85)

      doc.text(this.transmission.codeService.value, 165, 93)

      //delivery
      const wrappedDeliveryAddress = doc.splitTextToSize(this.deliveryAddress, 55)

      doc.text(wrappedDeliveryAddress, 38, 115)
      doc.text(this.deliveryPhone, 38, 137)
      doc.text(this.deliveryFax, 38, 144)
      doc.text(this.deliveryEmail, 38, 150)

      if (this.deliveryDate) {
        doc.text(format(new Date(this.deliveryDate), 'dd/MM/yyyy'), 82, 163)
      }

      doc.text(this.purchase.quantity, 145, 205)
      doc.text(String(this.totalHt), 170, 205)
      doc.text(String(this.totalHt), 170, 227)

      //pharmacist
      doc.text(this.pharmacist.name, 137, 124)
      doc.text(this.pharmacist.phone, 137, 130)
      doc.text(this.pharmacist.email, 137, 136)

      //signature data
      doc.addImage(data, 'JPEG', 82, 250, 100, 25)
      doc.text(this.patientId, 72, 230)
      doc.setFont(undefined, 'bold').text('Nom du docteur prescripteur :', 145, 240)
      doc.setFont(undefined, 'normal').text(this.doctorName, 145, 245)
      doc.text(`le ${format(new Date(this.signatureDate), 'dd/MM/yyyy')}`, 38, 275)

      //transform address
      // doc.setFillColor(255, 255, 255)
      // doc.setDrawColor(255, 255, 255)
      // doc.rect(70, 275, 80, 18, 'F')

      // doc.text('BIOGEN France SAS', 75, 280)
      // doc.text('Tour CBX 1, Passerelle des Reflets', 75, 284)
      // doc.text('92913, Paris La Defense', 75, 288)
      // doc.text('Cedex 398 410 126 RCS Nanterre', 75, 292)

      //saving pdf
      const docBlob = doc.output('blob')
      doc.save(`${this.patientId}-order.pdf`)

      const file = new File([docBlob], `${this.patientId}-order.pdf`, {
        type: 'application/pdf'
      })

      const formData = new FormData()
      formData.append('file', file)

      await axios.post(`/skyclarys/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-impersonificated-user': this.$store.state.impersonificatedUser
        }
      })
    }
  },
  computed: {
    totalHt: {
      get() {
        if (this.purchase.quantity && this.purchase.quantity % 90 === 0) {
          return this.purchase.quantity * 257.7778
        }
        return 0
      }
    }
  }
}
</script>

<style>
.wrapper-signature {
  width: '100%';
  padding: 8px 16px;
  height: 200px;
  border: solid 3px #d1d1d1;
  border-radius: 10px;
  position: relative;

  .signature-pad {
    position: absolute;
    top: 0;
  }

  &::before {
    content: 'SIGNER ICI';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #efefef;
    font-size: 3em;
  }
}

.wrapper-signature-error {
  border: solid 3px red;
}
</style>
